<template>
    <!-- 场地预约页面 -->
    <div class="reservation">
        <!-- <div class="button-group">
            <Icon
                class="download click-op"
                type="ios-cloud-download"
                @click="goTonew"
            />
        </div> -->
        <div class="container">
            <div class="menu-container">
                <div class="header">{{ "场地预订" | language }}</div>
                <div class="menu">
                    <div
                        v-for="(item, index) in door"
                        class="menu-child"
                        :key="item._id + index"
                        :class="{ active: item._id == activeId }"
                        @click="handleActive(item._id)"
                    >
                        {{ item.name | language }}
                    </div>
                </div>
            </div>
            <div class="common-list">
                <div
                    class="common"
                    v-for="(item, index) in dayArr"
                    :key="'comm' + index"
                >
                    <div class="header">{{ showDate(item.label) }}</div>
                    <div
                        class="item"
                        :class="{ 'border-bottom': (row.index + 1) % 4 == 0 }"
                        :style="{ flex: row && row.fixed }"
                        v-for="(row, j) in item.arr"
                        :key="j"
                        @click="handleClick(item.label, row)"
                    >
                        <p class="background" v-if="showColor(row)">
                            <span class="item-time">{{
                                row.fixed && row.time
                            }}</span>
                            <span class="item-cancel">{{
                                "取消" | language
                            }}</span>
                        </p>
                        <p class="nomarl" v-if="row.fixed && !showColor(row)">
                            {{ row.fixed && row.time }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-model="show"
            :title="'请选择预约时间' | language"
            @on-ok="save"
        >
            <Form :model="formItem" :label-colon="true" :label-width="120">
                <FormItem :label="'选择预约时间' | language">
                    <TimePicker
                        v-model="formItem.time"
                        format="HH:mm"
                        :steps="[1, 60]"
                        type="timerange"
                        placement="bottom-start"
                        :placeholder="'请选择预约时间' | language"
                        style="width: 168px"
                    ></TimePicker>
                </FormItem>
                <FormItem :label="'计划使用主要设备' | language">
                    <Input
                        v-model="formItem.device"
                        :placeholder="'请输入计划使用主要设备' | language"
                    />
                </FormItem>
                <FormItem :label="'事由简述' | language">
                    <Input
                        type="textarea"
                        v-model="formItem.intro"
                        :autosize="true"
                        :placeholder="'请输入事由简述' | language"
                    />
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
export default {
    data() {
        return {
            door: [],
            dayArr: [],
            show: false,
            activeId: "", //侧边栏激活id
            clickIndex: 0,
            formItem: {
                time: "",
                device: "",
                intro: ""
            }
        };
    },
    mounted() {
        this.fetchRoom();
    },
    methods: {
        goTonew() {
            // window.open("http://localhost:8888/test", "_blank");
        },
        //日历回调
        onPanelChange(value, mode) {
            console.log(value, mode);
        },
        //根据用户id以及审核状态判断显示样式
        showColor(row) {
            if (
                this.admin &&
                row.userId == this.admin._id &&
                row.status == "approved"
            ) {
                return true;
            } else {
                return false;
            }
        },
        //点击左侧菜单
        handleActive(id) {
            this.activeId = id;
            this.fetchData();
        },
        //获取场地
        async fetchRoom() {
            let res = await this.$http.get(
                "/room/name?pageSize=9999&pageNum=0"
            );
            if (res.length) {
                this.door = res;
                this.activeId = res[0]._id;
                this.fetchData();
            }
        },
        //获取最近7天数据
        async fetchData() {
            if (!this.activeId) {
                return this.$Message.error(languageTranslation("获取数据失败"));
            }
            let res = await this.$http.get(
                `/room/reservation/${this.activeId}`
            );

            this.getDayArr(res);
        },

        //保存表单
        async save() {
            if (
                !this.formItem.time.length ||
                !this.formItem.device ||
                !this.formItem.intro
            ) {
                return this.$Message.error(
                    languageTranslation("请确保预约信息完整")
                );
            }
            let arr = this.dayArr[this.clickIndex].arr.filter(item => {
                return item.fixed > 0;
            });

            let begin = this.formItem.time[0].split(":")[0].toString();
            if (begin < 10) {
                begin = begin[1];
            }
            let end = this.formItem.time[1].split(":")[0].toString();
            if (end < 10) {
                end = end[1];
            }
            if (begin == 23) {
                end = 24;
            }
            if (begin == end) {
                return this.$Message.error(
                    languageTranslation("时间段必须大于等于一个小时")
                );
            }
            //判断时间段预约重复
            if (arr.length) {
                for (let item of arr) {
                    if (
                        (begin >= item.beginAt && end <= item.endAt) || //被包含
                        (begin <= item.beginAt && end >= item.endAt) //包含
                    ) {
                        return this.$Message.error(
                            languageTranslation("该预约时间段内已有预约")
                        );
                    } else if (
                        (begin < item.beginAt &&
                            item.beginAt < end &&
                            end < item.endAt) ||
                        (begin > item.beginAt &&
                            item.endAt > begin &&
                            end > item.endAt)
                    ) {
                        return this.$Message.error(
                            languageTranslation("该预约时间段重复")
                        );
                    }
                }
            }
            let res = await this.$http.post("/room/reservation", {
                roomId: this.activeId,
                date: this.dayArr[this.clickIndex].label,
                beginAt: +begin,
                endAt: +end,
                plan: this.formItem.device,
                reason: this.formItem.intro
            });
            this.fetchData();
            this.$Message.success(
                languageTranslation("已提交预约申请，请等待管理员审核")
            );
            //重制表单
            this.$data.formItem = this.$options.data().formItem;
        },

        //点击预约格子处理方法
        async handleClick(label, row) {
            if (!this.admin) {
                await this.$Modal.confirm({
                    title: languageTranslation("登录后才能进行此操作"),
                    content: languageTranslation("是否立即跳转到登录页面？"),
                    okText: languageTranslation("确定"),
                    cancelText: languageTranslation("取消"),
                    onOk: () => {
                        this.$router.push("/common/login");
                    }
                });
                return;
            }
            //hook:缺少jacc的判断
            if (row.fixed && !this.showColor(row)) {
                return this.$Message.error(
                    languageTranslation("该时间段已被预约！")
                );
            } else if (row.fixed && this.showColor(row)) {
                //当前点击的格子是自己预约的
                let ifCancel = false;
                //取消操作
                this.$Modal.confirm({
                    title: languageTranslation("您将取消该预约"),
                    content: `<p>${languageTranslation("确定此操作？")}</p>`,
                    okText: languageTranslation("确定"),
                    cancelText: languageTranslation("取消"),
                    onOk: () => {
                        this.cancelRomm(row);
                    },
                    onCancel: () => {}
                });

                return;
            }
            let index = this.dayArr.findIndex(item => item.label == label);
            this.formItem.time = [
                `${this.numLen(row.index)}:00`,
                `${this.numLen(row.index + 1)}:00`
            ];
            this.clickIndex = index;

            this.show = true;
        },
        //单位数字转换成0X格式
        numLen(num) {
            if (num.toString().length == 1) {
                return `0${num}`;
            }
            return num;
        },
        //取消预约
        async cancelRomm(row) {
            let res = await this.$http.put(`/room/cancel/${row.reservationId}`);
            if (!res) {
                return this.$Message.error(languageTranslation("取消预约失败"));
            }
            this.fetchData();
            return this.$Message.success(languageTranslation("取消预约成功"));
        },

        //获取最近7天数据
        getDayArr(res) {
            let arr = [];
            //获取最近7天
            for (let i = 0; i < 180; i++) {
                let obj = {};
                obj.label = this.getDay(i);
                //创建24个格子
                let arr1 = new Array(24);
                for (let j = 0; j < 24; j++) {
                    arr1[j] = { index: j, time: `${j}:00-${j + 1}:00` };
                }
                obj.arr = arr1;
                //如果有res
                if (res.length) {
                    //过滤出当天的预约状态
                    let reservation = res.filter(item => {
                        return item.date == this.getDay(i);
                    });

                    if (reservation.length) {
                        reservation[0].reservations.map(item => {
                            //处理显示的时间
                            let time;
                            if (obj.arr.length < item.beginAt) {
                                if (item.beginAt == 23) {
                                    time = "23:00-24:00";
                                }
                            } else {
                                time = `${obj.arr[item.beginAt].index}:00-${obj
                                    .arr[item.beginAt].index +
                                    item.endAt -
                                    item.beginAt}:00`;
                            }
                            //差值
                            let step = item.endAt - item.beginAt;
                            if (step < 0) {
                                step = 1;
                            }
                            //开切点
                            let start = item.beginAt;
                            if (obj.arr.length < start) {
                                start = obj.arr.length - 1;
                            }
                            //切割格子方法
                            obj.arr.splice(start, step, {
                                ...item,
                                fixed: step,
                                index: item.beginAt,
                                time: time
                            });
                        });
                    }
                }
                arr.push(obj);
            }
            this.dayArr = arr;
        },
        //显示的日期
        showDate(time) {
            let date = new Date(time);
            return date.getMonth() + 1 + "." + date.getDate();
        },
        //获取日期 getDay(0)今天 aa=1明天
        getDay(aa) {
            let date1 = new Date(),
                date2 = new Date(date1);
            date2.setDate(date1.getDate() + aa);
            let month = date2.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            let day = date2.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            let time2 = date2.getFullYear() + "-" + month + "-" + day;
            return time2;
        }
    }
};
</script>

<style lang="less" scoped>
.reservation {
    width: 957px;
    .button-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
        .download,
        .calendar-icon {
            font-size: 26px;
            padding: 10px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .container {
        display: flex;
        align-items: flex-start;
        text-align: center;
        margin-bottom: 40px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
        .common-list {
            flex: 1;
            display: flex;
            align-items: flex-start;
            text-align: center;
            overflow-x: auto;
        }
        .menu {
            width: 223px;
            height: 504px;
            font-size: 18px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
            .menu-child {
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                &:hover {
                    cursor: pointer;
                    user-select: none;
                    width: 238px;
                    transform: translateX(-15px);
                    padding-left: 15px;
                    background-color: rgba(44, 141, 252, 1);
                    color: #fff;
                }
            }
            .active {
                cursor: pointer;
                user-select: none;
                width: 238px;
                padding-left: 15px;
                transform: translateX(-15px);
                background-color: rgba(44, 141, 252, 1);
                color: #fff;
            }
        }
        .common {
            width: 105px;
            text-align: center;
            display: flex;
            flex-direction: column;
            height: 569px;
            flex-shrink: 0;
            .item {
                font-size: 12px;
                flex: 1;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                &:hover {
                    user-select: none;
                    cursor: pointer;
                }
                > p {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .border-bottom {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            .background {
                background-color: orange;
                color: #fff;
                .item-time {
                    display: inline-block;
                }
                .item-cancel {
                    display: none;
                }
                &:hover {
                    .item-cancel {
                        display: inline-block;
                    }
                    .item-time {
                        display: none;
                    }
                }
            }
            .nomarl {
                background-color: rgba(216, 216, 216, 1);
                color: #606266;
            }
        }
        .header {
            height: 65px;
            line-height: 65px;
            background-color: #0c2433;
            color: #fff;
            font-size: 18px;
        }
        //   > div > div {
        //     height: 80px;
        //     line-height: 80px;
        //   }
    }
}
@media screen and (max-width: 960px) {
    .reservation {
        width: 100%;
        .container {
            overflow: auto;
            .header {
                font-size: 16px;
            }
            .common {
                width: 100px;
            }
            .menu {
                width: 100px;
                font-size: 14px;
                .menu-child {
                    width: 100%;
                    &:hover {
                        width: 100%;
                        padding-left: 0;
                        transform: translateX(0);
                    }
                }
                .active {
                    padding-left: 0;
                    transform: translateX(0);
                }
            }
        }
    }
}
</style>
